<!--
 * @Author: gaojingran
 * @Date: 2021-03-20 13:40:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-25 16:50:57
 * @Description: Login
-->

<style lang="less" scoped>
@import './index.less';
</style>

<template>
  <NeoScroll class="full">
    <div class="login">
      <div class="login-header">
        <div class="logo"></div>
        <a-icon class="help c-3" type="question-circle" @click="jumpHelp" />
      </div>
      <div class="login-body">
        <div class="container">
          <div class="left">
            <div class="image">
              <img src="~@/assets/image/PM-login-img.png" alt="login" />
            </div>
            <div class="title">
              <span class="divider"></span>
              <span class="text">{{ $t('login.title') }}</span>
              <span class="divider"></span>
            </div>
            <p class="sub-title">{{ $t('login.subTitle') }}</p>
          </div>
          <div class="right">
            <div class="image">
              <img src="~@/assets/image/login-form-img.svg" alt="form" />
            </div>
            <div class="form">
              <div class="form-container">
                <a-form-model ref="loginForm" layout="vertical" :model="form" :rules="rules">
                  <a-form-model-item prop="username">
                    <a-input
                      v-model="form.username"
                      size="large"
                      autocomplete="off"
                      :placeholder="$t('login.form_account_holder')"
                      @keyup.native.enter="handleLogin"
                      @blur="queryTenantCode"
                    >
                      <a-icon class="c-3" slot="prefix" type="mail" />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item size="large" prop="password">
                    <a-input
                      v-model="form.password"
                      size="large"
                      type="password"
                      autocomplete="off"
                      :placeholder="$t('login.form_password_holder')"
                      @keyup.native.enter="handleLogin"
                    >
                      <a-icon class="c-3" slot="prefix" type="lock" />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="bizTenantCode">
                    <a-input
                      v-model="form.bizTenantCode"
                      size="large"
                      autocomplete="off"
                      :placeholder="$t('login.form_tenant_code_holder')"
                      @keyup.native.enter="handleLogin"
                    >
                      <a-icon class="c-3" slot="prefix" type="book" />
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="captcha">
                    <div class="verify-item">
                      <a-input
                        size="large"
                        v-model="form.captcha"
                        :placeholder="$t('login.form_verify_holder')"
                        @keyup.native.enter="handleLogin"
                      >
                        <a-icon class="c-3" slot="prefix" type="safety-certificate" />
                      </a-input>
                      <div class="code">
                        <img :src="captchaPath" @click="getCaptcha()" />
                      </div>
                    </div>
                  </a-form-model-item>
                </a-form-model>
                <a-button class="login-btn" block type="primary" @click="handleLogin" :loading="loading">
                  {{ $t('login.submit') }}
                </a-button>
                <div class="careful">
                  <p class="sub-title bold">{{ $t('login.chrome') }}</p>
                  <a class="fr" type="link" @click="forgetVisible = true"> {{ $t('login.forget_password') }}? </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-footer"></div>
      <!-- 忘记密码 -->
      <ForgetPassword :visible.sync="forgetVisible" />
    </div>
  </NeoScroll>
</template>

<script>
import Cookies from 'js-cookie'
import { storage } from '@/utils/storage'
import { uuid } from '@/utils/utils'
import ForgetPassword from './components/ForgetPassword'
import md5 from 'js-md5'
const { validate, storageKey } = window.$g

export default {
  name: 'Login',
  components: {
    ForgetPassword,
  },
  data() {
    return {
      forgetVisible: false,
      loading: false,
      captchaPath: '',
      form: {
        username: storage.get(this.userInfoKey)?.username || '',
        password: '',
        captcha: '',
        uuid: '',
        client: undefined,
        supplier: undefined,
        bizTenantCode: storage.get(this.userInfoKey)?.bizTenantCode || '',
      },
      cusEntity: [],
      supplierEntity: [],
      rules: {
        username: [validate.required],
        password: [validate.required, validate.min_string_6],
        bizTenantCode: [validate.required],
        captcha: [
          {
            required: true,
            message: this.$t('login.noverify'),
          },
        ],
      },
    }
  },
  computed: {
    userInfoKey() {
      return `GientCloud-username_${this.form.username}`
    },
    userInfo() {
      return storage.get(this.userInfoKey)
    },
  },
  mounted() {
    this.getCaptcha()
  },
  methods: {
    queryTenantCode() {
      const has = storage.has(this.userInfoKey)
      console.log(`has`, has)
      if (has) {
        const data = storage.get(this.userInfoKey)
        console.log(`data`, data)
        const bizTenantCode = data?.bizTenantCode
        this.form.bizTenantCode = bizTenantCode
      } else {
        this.form.bizTenantCode = ''
      }
    },
    // 获取验证码
    getCaptcha() {
      this.form.uuid = uuid()
      this.captchaPath = `${window.SITE_CONFIG['apiURL']}${window.$g.api['auth_captcha_no_method']}?uuid=${this.form.uuid}`
    },
    // 登录
    handleLogin() {
      if (this.loading) return
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            let submitForm = JSON.parse(JSON.stringify(this.form))
            submitForm.systemType = window.$g.systemType.order
            submitForm.userType = window.$g.userType.sup
            submitForm.password = md5(submitForm.password)
            const data = await this.$http('login', {
              ...submitForm,
            })
            // 记录账号
            const { username, bizTenantCode } = this.form
            const userInfo = {
              username,
              bizTenantCode,
            }
            storage.set(this.userInfoKey, userInfo)
            // token
            Cookies.set(storageKey.TOKEN, data.token)
            // 进系统
            this.$router.replace({ name: 'main' })
          } catch (err) {
            this.loading = false
            this.form.captcha = ''
            this.getCaptcha()
            this.$httpNotify(err)
          }
        }
      })
    },

    jumpHelp() {
      window.open(window.SITE_CONFIG['helpUrl'], '_blank')
    },
  },
}
</script>
